import { Store } from 'vuex';

import { USER_LOGOUT } from '../../../core/store/modules/coreModule';
import { clearFnCache, memoizePromise } from '../../../utils/memoizePromise';
import { Form } from '../api/attributes';
import formsApi from '../api/forms';
import { IFormsService } from './types';

export class FormsService implements IFormsService {
  /**
   * @param store - vuex store
   */
  constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  /**
   * @inheritdoc
   */
  public getFormByAlias: (formAlias: string) => Promise<Form.Form> = memoizePromise(
    async (formAlias: string) => {
      return await formsApi.getFormById(formAlias);
    }
  );

  /**
   * Clears cache
   */
  private clearCache() {
    clearFnCache(this.getFormByAlias);
  }
}
