<template>
  <pui-dialog
    ref="dialog"
    :showCloseButton="false"
    transition="slide-up"
    class="com-upload-dialog com-upload-dialog__root"
    noAutoClose
    @outsideClick="onRequestClose"
    @click.stop
  >
    <!-- Upload action buttons -->
    <template #dialog-action>
      <div v-if="!showConfirmClose" class="dialog-action">
        <pui-button class="upload-button btn-secondary btn-outline" @click="onRequestClose">
          {{ $t('common.upload.dialog.buttons.cancel') }}
        </pui-button>
        <pui-button
          :disabled="!canImport"
          variant="primary"
          class="upload-button"
          @click="$emit('import')"
        >
          {{ $t('common.upload.dialog.buttons.confirm') }}
        </pui-button>
      </div>
      <!-- Confirm close buttons -->
      <div v-if="showConfirmClose" slot="dialog-action" class="dialog-action">
        <pui-button class="confirm-button" outline @click="onCancelClose">
          {{ $t('common.upload.dialog.confirm_close.buttons.cancel') }}
        </pui-button>
        <pui-button variant="primary" class="confirm-button" @click="close">
          {{ $t('common.upload.dialog.confirm_close.buttons.confirm') }}
        </pui-button>
      </div>
    </template>

    <template #title>
      <i18n path="common.upload.dialog.title.main" class="com-upload-dialog__title">
        <template #primary>
          {{ uploadConfiguration ? $piivoTranslateLabel(uploadConfiguration.label) : null }}
        </template>

        <template #secondary>
          <span class="subtitle">
            <template v-if="showConfirmClose">
              {{ $t('common.upload.dialog.title.secondary.confirm_close') }}
            </template>
            <template v-else>
              {{ $t('common.upload.dialog.title.secondary.choose_media') }}
            </template>
          </span>
        </template>
      </i18n>
    </template>

    <template #default>
      <div class="com-upload-dialog__description">
        {{ uploadConfiguration ? $piivoTranslateLabel(uploadConfiguration.description) : null }}
      </div>

      <pui-flex direction="row" class="com-upload-dialog__content-wrapper" flex="1">
        <pui-flex
          v-if="showConfirmClose"
          class="com-upload-dialog__confirm-close-container"
          direction="column"
          flex="1"
        >
          <span class="confirm-close-message">
            {{ $t('common.upload.dialog.confirm_close.message') }}
          </span>
        </pui-flex>
        <pui-flex
          v-else-if="showError"
          class="com-upload-dialog__error-container"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          <i class="mdi mdi-close-circle-outline error-icon" />
          <pui-flex direction="column" justifyContent="center">
            <div class="error-message">
              <p>{{ $t('common.upload.dialog.error.message') }}</p>
              <p>
                <a class="pui-link" @click="reloadAfterError">
                  {{ $t('common.upload.dialog.error.reload.click') }}
                </a>
              </p>
            </div>
          </pui-flex>
        </pui-flex>
        <template v-else-if="canShowMainContent">
          <pui-flex
            :flex="showForm ? '50%' : '100%'"
            :class="{ 'com-upload-dialog__drop-zone-container': true, small: showForm }"
          >
            <pui-common-drop-zone-with-preview
              :files="files"
              class="com-upload-dialog__drop-zone"
              :accept="dropZoneAccept"
              :disabled="disableAddFiles"
              @addFile="addFile"
              @removeFile="removeFile"
            >
              <template v-if="showIncorrectType" #warning>
                <pui-error picto="mdi-alert" class="com-upload-dialog__error-incorrect-type">
                  <pui-flex direction="column">
                    <i18n path="common.upload.dialog.error_incorrect_type.primary"></i18n>
                    <i18n path="common.upload.dialog.error_incorrect_type.secondary">
                      <template #types>
                        {{ allowedTypesStr }}
                      </template>
                    </i18n>
                  </pui-flex>
                </pui-error>
              </template>
            </pui-common-drop-zone-with-preview>
          </pui-flex>
          <pui-flex v-if="showForm" flex="50%" direction="column" class="com-upload-dialog__form">
            <slot name="form"></slot>
          </pui-flex>
        </template>
      </pui-flex>
    </template>
  </pui-dialog>
</template>

<script>
import services from '../../../../core/services';
import { I18nManager } from '../../../../core/services/i18nManager';

/**
 * Dropzone accept separator
 */
const DROPZONE_ACCEPT_SEPARATOR = ',';

export default {
  name: 'ComUploadDialog',
  props: {
    /**
     * Enables the import button
     */
    canImport: {
      type: Boolean,
      required: true,
    },
    /**
     * Shows the error instead of the upload
     */
    showError: {
      type: Boolean,
      required: true,
    },
    /**
     * Reloads the upload
     */
    reloadAfterError: {
      type: Function,
      required: true,
    },
    /**
     * If the form wrapper and slot should be displayed
     */
    showForm: {
      type: Boolean,
      default: true,
    },
    /**
     * The upload configuration
     */
    uploadConfiguration: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      files: [],
      showConfirmClose: false,
      showIncorrectType: false,
      allowedTypesFormatter: null,
    };
  },
  computed: {
    /**
     * @returns {boolean} if can show the main upload content
     */
    canShowMainContent() {
      return !this.showConfirmClose && !this.showError;
    },
    /**
     * @returns {string} the "accept" configuration for the input
     */
    dropZoneAccept() {
      if (!this.uploadConfiguration) {
        return [];
      }

      return [
        ...this.uploadConfiguration.fileOptions.supportedExtensions,
        ...this.uploadConfiguration.fileOptions.supportedMimeTypes,
      ].join(DROPZONE_ACCEPT_SEPARATOR);
    },
    /**
     * @returns {string|null} the allowed types to upload
     */
    allowedTypesStr() {
      return this.uploadConfiguration.fileOptions.supportedExtensions.length
        ? this.allowedTypesFormatter.format(
            this.uploadConfiguration.fileOptions.supportedExtensions
          )
        : null;
    },
    /**
     * @returns {boolean} if should disable adding files
     */
    disableAddFiles() {
      return this.files.length >= this.uploadConfiguration.fileOptions.maxFiles;
    },
  },
  watch: {
    /**
     * Emits the new value
     * @param nextValue - next value
     * @param prevValue - previous value
     */
    canShowMainContent(nextValue, prevValue) {
      if (nextValue !== prevValue) {
        this.$emit('canShowMainContent', nextValue);
      }
    },
  },
  methods: {
    // #region Dialog
    /**
     * Opens the dialog
     */
    open() {
      this.$refs.dialog.open();
    },
    /**
     * Sets the close confirmation
     */
    onRequestClose() {
      if (this.files.length && this.files.length > 0) {
        this.showConfirmClose = true;
      } else {
        this.close();
      }
    },
    /**
     * Removes the close confirmation
     */
    onCancelClose() {
      this.showConfirmClose = false;
    },
    /**
     * Closes the dialog and resets state
     */
    close() {
      this.$refs.dialog.close();
      this.resetState();
      this.$emit('closed');
    },
    /**
     * Initializes the state of the dialog
     * for a new import
     */
    resetState() {
      this.files = [];
      this.showConfirmClose = false;
      this.showIncorrectType = false;
    },
    // #endregion

    // #region FILES
    /**
     * Adds a file
     *
     * @param {object} file - the file to add
     */
    addFile(file) {
      if (this.disableAddFiles || file.size > this.uploadConfiguration.fileOptions.maxFileSize) {
        return;
      }

      const fileExt = file.name.slice(file.name.lastIndexOf('.'));
      if (
        this.uploadConfiguration.fileOptions.supportedMimeTypes.includes(file.type) ||
        this.uploadConfiguration.fileOptions.supportedExtensions.includes(fileExt)
      ) {
        this.showIncorrectType = false;
        this.files.push(file);
        this.$emit('changeFiles', this.files);
      } else {
        this.showIncorrectType = true;
      }
    },
    /**
     * Removes the given file
     *
     * @param {object} file - the file to remove
     * @param {number} fileIndex - the index of the file to remove
     */
    removeFile(file, fileIndex) {
      if (fileIndex !== -1) {
        this.files.splice(fileIndex, 1);
        this.$emit('changeFiles', this.files);
      }
    },
    // #endregion
  },
  /**
   * Component mounted hook
   */
  mounted() {
    this.allowedTypesFormatter = new Intl.ListFormat(
      I18nManager.getI18nLocale(services.getService('languages').getSelectedLocale()),
      {
        style: 'long',
        type: 'disjunction',
      }
    );
  },
};
</script>
