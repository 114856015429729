import puiCommonMessageBox from './alerts/MessageBox';
import puiAttributeDetailPanel from './attributes/AttributeDetailPanel.vue';
import puiAttributeExternalLink from './attributes/AttributeExternalLink.vue';
import puiAttributeFilterPanel from './attributes/AttributeFilterPanel.vue';
import puiAttributeGroupPanel from './attributes/AttributeGroupPanel.vue';
import puiAttributePanel from './attributes/AttributePanel.vue';
import puiAttributeTable from './attributes/AttributeTable.vue';
import puiAttributeTree from './attributes/AttributeTree.vue';
import puiMultipleAttributePanel from './attributes/MultipleAttributePanel.vue';
import puiUnsavedAttributesDialog from './attributes/UnsavedAttributesDialog';
import puiBallLoader from './BallLoader';
import puiBigPreview from './BigPreview';
import puiError from './Error';
import puiCorruptedThumbnail from './thumbnail/CorruptedThumbnail.vue';
import puiFileThumbnailViewer from './thumbnail/FileThumbnailViewer.vue';
import puiThumbnail from './thumbnail/Thumbnail.vue';
import puiThumbnailAction from './thumbnail/ThumbnailAction.vue';
import puiThumbnailMainAction from './thumbnail/ThumbnailMainAction.vue';
import ComUploadButton from './uploads/ComUploadButton.vue';
import ComUploadDialog from './uploads/ComUploadDialog.vue';

export {
  puiAttributeGroupPanel,
  puiMultipleAttributePanel,
  puiAttributePanel,
  puiAttributeFilterPanel,
  puiAttributeDetailPanel,
  puiAttributeTable,
  puiAttributeTree,
  puiAttributeExternalLink,
  puiCommonMessageBox,
  puiFileThumbnailViewer,
  puiCorruptedThumbnail,
  puiThumbnail,
  puiThumbnailMainAction,
  puiThumbnailAction,
  puiBigPreview,
  puiUnsavedAttributesDialog,
  puiError,
  puiBallLoader,
  ComUploadButton,
  ComUploadDialog,
};

export const components = [
  puiAttributeGroupPanel,
  puiMultipleAttributePanel,
  puiAttributePanel,
  puiAttributeFilterPanel,
  puiAttributeDetailPanel,
  puiAttributeTable,
  puiAttributeTree,
  puiAttributeExternalLink,
  puiCommonMessageBox,
  puiFileThumbnailViewer,
  puiCorruptedThumbnail,
  puiThumbnail,
  puiThumbnailMainAction,
  puiThumbnailAction,
  puiBigPreview,
  puiUnsavedAttributesDialog,
  puiError,
  puiBallLoader,
  ComUploadButton,
  ComUploadDialog,
];
