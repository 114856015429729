import { render, staticRenderFns } from "./ComUploadButton.vue?vue&type=template&id=0e5e5c14&"
import script from "./ComUploadButton.vue?vue&type=script&lang=js&"
export * from "./ComUploadButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports