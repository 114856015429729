import Vue from 'vue';

import { ResolvedResponse } from '../../../core/types/http';
import { Form } from './attributes';

const API_URL_BASE = '/api/piivo/v1/forms';

export default {
  /**
   *
   * @param formAlias - alias of the form
   * @returns the form
   */
  async getFormById(formAlias: string): Promise<Form.Form> {
    const response = (await Vue.http.get(
      `${API_URL_BASE}/${formAlias}`
    )) as ResolvedResponse<Form.Form>;
    return response.body;
  },
};
