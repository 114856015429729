import './components/table/editors';
import './components/table/renderers';
import './static/style/_all.scss';

import Vue from 'vue';

import { getExtensionPoint } from '../../core/extensionPoints';
import { components } from './components';
import detailsHelper from './helpers/detailsHelper';
import filtersHelper from './helpers/filtersHelper';
import tableHelper from './helpers/tableHelper';
import { ActionsService } from './services/actionsService';
import { FormsService } from './services/formsService';
import { SessionSettingsService } from './services/sessionSettingsService';
import { UserSettingsService } from './services/userSettingsService';
import {
  NAMESPACE as SESSION_SETTINGS_NAMESPACE,
  SessionSettingsModule,
} from './store/sessionSettings';
import { NAMESPACE as USER_SETTINGS_NAMESPACE, UserSettingsModule } from './store/userSettings';

const moduleName = 'common';

/**
 * Function to install common app module
 * */
export function install(platformExtension, useCache) {
  for (const component of components) {
    Vue.component(component.name, component);
  }

  platformExtension.registerStoreModule(USER_SETTINGS_NAMESPACE, UserSettingsModule);
  platformExtension.registerStoreModule(SESSION_SETTINGS_NAMESPACE, SessionSettingsModule);

  /* Install services */
  platformExtension.services.addService('actions', new ActionsService());
  platformExtension.services.addService('userSettings', new UserSettingsService());
  platformExtension.services.addService('sessionSettings', new SessionSettingsService());
  platformExtension.services.addService('forms', new FormsService(platformExtension.store));

  /* Install helpers */
  platformExtension.helpers.addHelper('detailsHelper', detailsHelper);
  platformExtension.helpers.addHelper('filtersHelper', filtersHelper);
  platformExtension.helpers.addHelper('tableHelper', tableHelper);
}

getExtensionPoint('platform').services.getService('moduleManager').registerModule({
  name: moduleName,
  install,
});
