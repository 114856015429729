<template>
  <pui-popper class="upload-button upload-button__root">
    <template #reference>
      <pui-header-button
        v-permission="permission"
        picto="mdi-download"
        class="upload-button upload-button__root"
        v-if="uploadsConfiguration.length > 0"
        :data-id="uploadsConfiguration.length === 1 ? uploadsConfiguration[0].action : ''"
        @click="
          uploadsConfiguration.length === 1 ? openUploadDialog(uploadsConfiguration[0]) : null
        "
      >
        {{ $t('poster.header.import') }}
      </pui-header-button>

      <component :is="dialogComponent" ref="dialog"></component>
    </template>

    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template #default="{ closePopper }">
      <template v-if="uploadsConfiguration.length > 1">
        <pui-flex class="upload-button__list" direction="column">
          <pui-button
            v-for="uploadConf in uploadsConfiguration"
            :key="uploadConf.alias"
            v-permission="uploadConf.permission"
            class="upload-btn"
            :data-id="uploadConf.action"
            :block="true"
            :flat="true"
            variant="secondary"
            @click="openUploadDialog(uploadConf)"
          >
            {{ uploadConf | piivoTranslate }}
          </pui-button>
        </pui-flex>
      </template>
    </template>
  </pui-popper>
</template>

<script>
import { CommonPermissions } from '../../constants/permissions';

export default {
  name: 'ComUploadButton',
  props: {
    /**
     * The dialog component to render
     */
    dialogComponent: {
      type: Object,
      required: true,
    },
    /**
     * The uploads configuration
     */
    uploadsConfiguration: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * Gets the permission to use for the upload button.
     *
     * @returns if there is a single upload configured with a permission,
     * it will be used, otherwise the default general permission is be used.
     */
    permission() {
      let permission = '';
      if (this.uploadsConfiguration.length === 1) {
        permission = this.uploadsConfiguration[0].permission;
      }

      if (permission.length > 0) {
        return permission;
      }

      return CommonPermissions.UploadCreate;
    },
  },
  methods: {
    /**
     * Opens the upload dialog
     * @param config - upload configuration
     */
    openUploadDialog(config) {
      if (this.$refs.dialog) {
        this.$refs.dialog.open(config);
      }
    },
  },
};
</script>
